import React, { Component } from 'react';
import './Contact.css';

class Contact extends Component {
	render() {
	return (
		<div id="contact" class="Contact">
			
			<div class="container-fluid">
				<div class="row row-contact">
					<div class="col-lg-7 container-contact">
						<div class="row align-items-center justify-content-center h-100" data-aos>
							<div>
								<p class="fade-up">
									<h3>Location</h3>
									5 Library Lane, 		<br></br>
									Stouffville, Ontario	<br></br>
									L4A 1E2					<br></br>
								</p>
								<p class="fade-up">
									<h3>Contact</h3>
									(416) 831-2327			<br></br>
									dianalynhutch@gmail.com	<br></br>
								</p>
								<p class="fade-up">
									<h3>Hours</h3>
									<table class="table-schedule table table-borderless">
										<tbody>
											<tr>
												<td>Tuesday</td>
												<td>9:30</td>
												<td>- 6:00</td>
											</tr>
											<tr>
												<td>Wednesday</td>
												<td>10:00</td>
												<td>- 7:00</td>
											</tr>
											<tr>
												<td>Thursday</td>
												<td>9:30</td>
												<td>- 5:30</td>
											</tr>
											<tr>
												<td>Friday</td>
												<td>9:30</td>
												<td>- 6:00</td>
											</tr>
											<tr>
												<td>Saturday</td>
												<td>8:30</td>
												<td>- 2:30</td>
											</tr>
										</tbody>
									</table>
								</p>
								
							</div>
							
						</div>
						
					</div>
					<div class="col-lg embed-responsive embed-responsive-4by3">
						<iframe class="embed-responsive-item"  id="gmap_canvas" src="https://maps.google.com/maps?q=5%20Library%20Lane%20Stouffville%20&t=&z=17&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" title="map"></iframe>
					</div>
				</div>
			</div>
			

		</div>
	);
	}
}

export default Contact;