import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
	render() { return (

		<div class="Footer">
			<div class="container">
				<div class="row align-items-center">
					<div class="col text-center">
						© 2020 Hutchinson Hair | Web Design by Travis Welygan
					</div>
				</div>
			</div>
		</div>

	);}
}

export default Footer;