import React, { Component } from 'react';
import './Services.css';

function Service(props) {
	return (
		<tr>
			<td>{props.name}</td>
			<td>{props.children}${props.price}</td>
		</tr>
	);
}

class Services extends Component {
	render() {
	return (
		<div id ="services" class="Services">
			
			<div class="container-fluid container-services py-4" data-aos>
				<div class="row justify-content-center">
					<div class="header-wrapper"><h2>Services</h2></div>
				</div>
				<br></br>
				<div class="row justify-content-center">
					<table class="table-services table table-borderless fade-up">
						<tbody>
							<Service name="BARBER CUT" 		price="30"></Service>
							<Service name="SHORT CUT" 		price="45"></Service>
							<Service name="MID/LONG CUT" 	price="60"></Service>
							<Service name="FLAT/CURLING IRON, ROLLERS" price="20+"></Service>
							<Service name="BLOWOUT" 		price="20+"></Service>
							<Service name="UP-DO" 			price="30+"></Service>
							<tr>
								<td>&nbsp;</td><td></td>
							</tr>
							<Service name="ROOTS TOUCH-UP" 	price="60+"></Service>
							<Service name="PRE-COLOUR" 		price="15"></Service>
							<Service name="FULL COLOUR" 	price="70+"></Service>
							<Service name="ORGANIC COLOUR" 	price="15">ADD </Service>
							<Service name="FULL HIGHLIGHTS" price="80+"></Service>
							<Service name="HALF HIGHLIGHTS" price="60+"></Service>
							<Service name="OMBRE/BALAYAGE" 	price="160+"></Service>
							<Service name="OLAPLEX" 		price="20+"></Service>
							<Service name="GLOSS" 			price="85"></Service>
							<Service name="KERATIN TREATMENT" price="175+"></Service>
						</tbody>
					</table>
				</div>
				<div class="row justify-content-center">Prices subject to change</div>
			</div>

		</div>
	);
	}
}

export default Services;

// PRICES SUBJECT TO CHANGE