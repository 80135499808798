import React from 'react';

import About from './components/About';
import Booking from './components/Booking';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import Navbar from './components/Navbar';
// import Reviews from './components/Reviews';
import Title from './components/Title';
import Services from './components/Services';

import './App.css';

function App() {

	return (
		<div>
			<Navbar></Navbar>
			<div class="nav-padding d-none d-sm-block top"></div>
			<Title></Title>
			<Booking></Booking>
			<About></About>
			<Services></Services>
			{/* <Reviews></Reviews> */}
			<Contact></Contact>
			<Gallery></Gallery>
			<Footer></Footer>
		</div>

		);
	}
	
export default App;
	