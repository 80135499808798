import React, { Component } from 'react';
import './Navbar.css';

class Navbar extends Component {
	render() {
	return (
		<div id="nav" class="Navbar d-none d-sm-block">

			<div class="container-fluid">
				<nav id="navbar" class="navbar navbar-expand-md">
					<a class="navbar-brand " href="#top">
						<span class="text-title">
							<span>Hutchinson <span class="text-title-bold">Hair</span></span>
						</span>
					</a>
					{/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button> */}

					<div class="collapse navbar-collapse" id="navbarSupportedContent">
						<ul class="navbar-nav">
							<li class="nav-item">
								<a class="nav-link" href="#about">About</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#services">Services</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#contact">Contact</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" href="#gallery">Instagram</a>
							</li>
						</ul>
					</div>
					<button type="button" class="btn booking-button" data-toggle="modal" data-target="#bookingModal">Book Now!</button>
				</nav>
			</div>
		</div>
	);
	}
}

export default Navbar;