import React, { Component } from 'react';
import './Gallery.css';

// import instaLogo from '../images/IG_Glyph_Fill.png';

class Gallery extends Component {
	render() {
	return (
		<div id="gallery" class="Gallery">

			<div class="insta-header container" data-aos>
				{/* <div class="row justify-content-center">
					<div class="header-wrapper"><h2>Instagram</h2></div>
				</div> */}
				<div class="fade-up">
					<div class="row justify-content-center">
						<a class="insta-logo" href="https://www.instagram.com/dianalynhairstylist/" target="_blank"  rel="noopener noreferrer">
							{/* <img class="insta-logo" src={instaLogo} alt="Instagram Logo"></img> */}
						</a>
					</div>
					<div class="row justify-content-center">
						<a class="insta-handle" href="https://www.instagram.com/dianalynhairstylist/" target="_blank"  rel="noopener noreferrer">
							@dianalynhairstylist
						</a>
					</div>
				</div>
				
			</div>
		
			{/* <div class="w-75"> */}
				<ul class="juicer-feed" data-feed-id="dianalynhairstylist" data-columns="5" data-per="15">
					<h1 class='referral'>
						{/* <a href='https://www.juicer.io'>Powered by Juicer.io</a> */}
					</h1>
				</ul>
			{/* </div> */}
			

		</div>
	);
	}
}



export default Gallery;