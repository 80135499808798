import React, { Component } from 'react';
import './Booking.css';

class Booking extends Component {

	render() {
	return (
		<div id="booking" class="Booking container-fluid">

			<div class="row justify-content-center d-sm-none">
				<button type="button" class="btn booking-button booking-button-lg" data-toggle="modal" data-target="#bookingModal">Book Now!</button>
			</div>

			<div class="modal" id="bookingModal" tabindex="-1" role="dialog" aria-labelledby="bookingModalTitle" aria-hidden="true">
				<div class="modal-dialog modal-xl" role="document">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="bookingModalTitle">Book an Appointment</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<iframe title="square" src="https://squareup.com/appointments/buyer/widget/b7fq5tua7oh18r/7339PKTJT9F3M"></iframe>
						</div>
					</div>
				</div>
			</div>

		</div>
	);
	}
}

export default Booking;