import React, { Component } from 'react';
import './Title.css';

class Title extends Component {
	render() {
	return (
		<div class="Title">

			<div class="container-fluid loader" data-aos>

				<div class="titletext">
					Hutchinson <span class="text-title-bold">Hair</span>
				</div>

				{/* <div class="container-fluid justify-content-center w-100 title-button-wrapper">			
					<button type="button" class="btn booking-button titleButton fade-up" data-toggle="modal" data-target="#bookingModal">
						Book Now!
					</button>
				</div> */}
				
				<div class="row h-100">
					<div class="col-sm loaderimg">
					</div>
					<div class="col-sm loaderimg">
					</div>
					<div class="col-sm loaderimg d-xs-block d-sm-none d-xl-block">
					</div>
				</div>
				
				
				
			</div>
		
		</div>
	);
	}
}

export default Title;