import React, { Component } from 'react';
import './About.css';

class About extends Component {

	render() {
	return (
		<div id="about" class="About">
			
			<div class="container-fluid">
				<div class="row align-items-center h-100">
					<div class="col about-bgimg-wrapper">
						<div class="about-bgimg-1 shadow"></div>
					</div>
					<div class="col-lg-7 col-xl-4 my-5" data-aos>
						<div class="row justify-content-center">
							<div class="header-wrapper"><h2>About me!</h2></div>
						</div>
						<br></br>
						<div class="about-text fade-up">
							<p>
								Hello! My name is Diana Hutchinson and I’d like to welcome you to Hutchinson Hair, my intimate hair studio in Stouffville, Ontario! 
							</p>
							<p>
								Let me tell you a bit about myself. I’m a master stylist with over 30 years behind the chair, and I’m dedicated to providing you with high quality hair services in a comfortable atmosphere. I work with you from start to finish to ensure you're happy with your hair and never lost in the shuffle.
							</p>
							<p>
								Feel free to head on over to my menu or instagram, and you can text, email, or call with any questions you may have.
							</p>
							<p>
								Looking forward to having you in my chair and experiencing great hair!
							</p>
						</div>
						
					</div>
					<div class="col d-none d-xl-block about-bgimg-wrapper">
						<div class="about-bgimg-2 shadow"></div>
					</div>
				</div>
			</div>

		</div>
	);
	}
}

export default About;